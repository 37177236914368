import Link from "next/link";
import { Container } from "../container";
import classes from "./articles.module.css";

interface ArticlesProps {
  title: string;
  articles: Array<any>;
  limit?: number;
}

interface ArticleCardProps {
  title: string;
  feature_image?: string;
  published_at: string;
  excerpt: string;
  permalink: string;
}

const ArticleCard = ({
  title,
  feature_image,
  published_at,
  excerpt,
  permalink,
}: ArticleCardProps) => {
  feature_image = feature_image || "/news-placeholder.png";

  const publishDateParts = new Date(published_at).toDateString().split(" ");
  const publishDate = [
    publishDateParts[2],
    publishDateParts[1],
    publishDateParts[3],
  ].join(" ");

  return (
    <div className={classes.marketing__articles_card}>
      <div
        className={classes.marketing__articles_card_image}
        style={{ backgroundImage: `url(${feature_image})` }}
      ></div>
      <div className={classes.marketing__articles_card_date}>{publishDate}</div>
      <Link href={permalink} legacyBehavior>
        <div className={classes.marketing__articles_card_title}>{title}</div>
      </Link>
      <div className={classes.marketing__articles_card_excerpt}>{excerpt}</div>
      <Link href={permalink} legacyBehavior>
        <svg
          className={classes.marketing__articles_card_link}
          xmlns="http://www.w3.org/2000/svg"
          width="49"
          height="49"
        >
          <g transform="rotate(90 24.5 24.5)">
            <circle cx="24.5" cy="24.5" r="24.5" fill="#00325a" />
            <g
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            >
              <path d="M18,33.483V7.5" transform="translate(6.573 4.082)" />
              <path
                d="M7.5,20.491,20.491,7.5,33.483,20.491"
                transform="translate(4.082 4.082)"
              />
            </g>
          </g>
        </svg>
      </Link>
    </div>
  );
};

const Articles = ({ title, articles, limit }: ArticlesProps) => (
  <div className={classes.marketing__articles}>
    <Container>
      <h2 className={classes.marketing__articles_title}>{title}</h2>
      {articles.length === 0 ? (
        <h2 style={{ textAlign: "center" }}>No articles found</h2>
      ) : (
        <div className={classes.marketing__articles_cards}>
          {articles.slice(0, limit).map((article) => (
            <ArticleCard key={`article_${article.id}`} {...article} />
          ))}
        </div>
      )}
    </Container>
  </div>
);

export { Articles };
