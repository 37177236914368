import { PropsWithChildren, ReactNode } from "react";
import classes from "./hero.module.css";

interface HeroProps {
  title: ReactNode;
  isSlim?: boolean;
  isTiny?: boolean;
  isCentered?: boolean;
  isClean?: boolean;
  img?: string;
}

const SliderHero = ({
  title,
  isSlim,
  isTiny,
  isCentered,
  children,
  img,
}: PropsWithChildren<HeroProps>) => {
  const classList = [classes.marketing__hero];
  if (isSlim) classList.push(classes.marketing__hero_slim);
  if (isTiny) classList.push(classes.marketing__hero_tiny);
  if (isCentered) classList.push(classes.marketing__hero_centered);
  if (img) classList.push(classes.marketing__hero_hasimage);

  return (
    <section className={classList.join(" ")}>
      {img && (
        <div
          className={classes.marketing__hero_image}
          style={{ backgroundImage: `url("${img}")` }}
        ></div>
      )}

      {img && !isSlim && (
        <svg
          className={classes.marketing__hero_corner}
          xmlns="http://www.w3.org/2000/svg"
          width="241"
          height="283"
        >
          <defs>
            <clipPath id="a">
              <path
                transform="translate(-9557 -2998)"
                fill="none"
                d="M0 0H241V283H0z"
              />
            </clipPath>
          </defs>
          <g transform="translate(9557 2998)" clipPath="url(#a)">
            <path
              d="M-522.006-189.828l220.639,98.235,107.243,352.926L-414.763,163.1Z"
              transform="rotate(-24 -11753.756 20230.622)"
              fill="#a7a6a6"
              opacity=".498"
            />
          </g>
        </svg>
      )}

      <div className={classes.marketing__heroContentContainer}>
        <h1>{title}</h1>
        {children}
      </div>
    </section>
  );
};

export { SliderHero };
