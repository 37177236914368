import { PropsWithChildren, useRef, useState } from "react";
import classes from "./slider.module.css";

interface SliderProps {}
interface SliderMarkersProps {
  slideCount: number;
  activeSlideIndex: number;
  clickHandler: Function;
}

interface SlideProps {}

const Slide = ({ children }: PropsWithChildren<SlideProps>) => (
  <div className={classes.marketing__slider_slide}>{children}</div>
);

const SliderMarkers = ({
  slideCount,
  activeSlideIndex,
  clickHandler,
}: SliderMarkersProps) => {
  return (
    <ul className={classes.marketing__slider_markers}>
      {new Array(slideCount).fill(0).map((_, i) => {
        const markerClasses = [classes.marketing__slider_markers_marker];
        if (i == activeSlideIndex)
          markerClasses.push(classes.marketing__slider_markers_marker_active);

        return (
          <li
            key={`slide_${i}`}
            onClick={() => clickHandler(i)}
            className={markerClasses.join(" ")}
          ></li>
        );
      })}
    </ul>
  );
};

const Slider = ({ children }: PropsWithChildren<SliderProps>) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const sliderContainerRef = useRef(null);

  // @ts-ignore
  const slideCount = children.length;

  const nextSlide = () => {
    setActiveSlideIndex(
      activeSlideIndex == slideCount - 1 ? 0 : activeSlideIndex + 1
    );
  };

  const prevSlide = () => {
    setActiveSlideIndex(
      activeSlideIndex == 0 ? slideCount - 1 : activeSlideIndex - 1
    );
  };

  const toSlide = (slideIndex: number) => setActiveSlideIndex(slideIndex);

  if (sliderContainerRef?.current) {
    const sliderContainer = sliderContainerRef.current;
    const firstSlide = sliderContainer.querySelector(
      `.${classes.marketing__slider_slide}:first-of-type`
    );

    if (firstSlide) {
      firstSlide.style.marginLeft = `-${activeSlideIndex * 100}vw`;
    }
  }

  return (
    <div className={classes.marketing__slider} ref={sliderContainerRef}>
      {children}

      <SliderMarkers
        slideCount={slideCount}
        activeSlideIndex={activeSlideIndex}
        clickHandler={toSlide}
      />

      <svg
        className={[
          classes.marketing__slider_arrow,
          classes.marketing__slider_arrow_left,
        ].join(" ")}
        onClick={() => prevSlide()}
        xmlns="http://www.w3.org/2000/svg"
        width="23.182"
        height="42.121"
      >
        <path
          data-name="Path 54"
          d="M22.121 1.062l-20 20 20 20"
          fill="none"
          stroke="#00ab9d"
          strokeWidth="3"
        />
      </svg>

      <svg
        className={[
          classes.marketing__slider_arrow,
          classes.marketing__slider_arrow_right,
        ].join(" ")}
        onClick={() => nextSlide()}
        xmlns="http://www.w3.org/2000/svg"
        width="23.182"
        height="42.121"
      >
        <path
          data-name="Path 55"
          d="M1.06 41.059l20-20-20-20"
          fill="none"
          stroke="#00ab9d"
          strokeWidth="3"
        />
      </svg>
    </div>
  );
};

export { Slider, Slide };
