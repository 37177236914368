import { PropsWithChildren, useState } from "react";
import { Button, ButtonContainer } from "../button";
import { Container } from "../container";
import { IconLogoMarkLight } from "../icons";
import classes from "./benefits.module.css";

interface BenefitsProps {}

interface BenefitSection {
  title: string;
  className?: string;
  icons: Array<BenefitSectionIcon>;
  ctaUrl?: string;
  buttonColor?: string;
}

interface BenefitSectionIcon {
  iconUrl: string;
  description: string;
}

const Benefits = ({}: PropsWithChildren<BenefitsProps>) => {
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);

  const sections = new Array<BenefitSection>(
    {
      title: "Entrepreneurs",
      ctaUrl: "/entrepreneurs",
      icons: [
        {
          iconUrl: "/icon-investors.svg",
          description: "Access to credible, active, sector-specific investors",
        },
        {
          iconUrl: "/icon-experts.svg",
          description: "Full holistic evaluation<br/>by industry experts",
        },
        {
          iconUrl: "/icon-exposure.svg",
          description: "1 year's exposure on the<br/>R8ER Report",
        },
        {
          iconUrl: "/icon-access.svg",
          description: "Access to potential advisors/<br/>board members",
        },
      ],
    },
    {
      title: "Investors",
      ctaUrl: "/investors",
      className: classes.marketing__benefits_light,
      icons: [
        {
          iconUrl: "/icon-businesses.svg",
          description:
            "Direct access to high-quality,<br/>early-stage, disruptive businesses",
        },
        {
          iconUrl: "/icon-vetting.svg",
          description: "Businesses vetted by top experts",
        },
        {
          iconUrl: "/icon-diligence.svg",
          description: "Enhanced due diligence",
        },
        {
          iconUrl: "/icon-advisors.svg",
          description: "Access to niche,<br/>sector-specific advisors",
        },
      ],
      buttonColor: "blue",
    }
  );

  const Tabs = sections.map((section, sectionIndex) => {
    const tabClasses = [classes.marketing__benefits_tab];
    if (sectionIndex == activeSectionIndex) {
      tabClasses.push(classes.marketing__benefits_tab_active);
    }

    return (
      <div
        key={sectionIndex}
        onClick={() => setActiveSectionIndex(sectionIndex)}
        className={tabClasses.join(" ")}
      >
        {section.title}
      </div>
    );
  });

  const MaybeShowCta = sections[activeSectionIndex]?.ctaUrl && (
    <ButtonContainer classList={[classes.marketing__benefits_buttonContainer]}>
      <Button
        buttonText="See More"
        buttonHref={sections[activeSectionIndex].ctaUrl}
        isBlue={sections[activeSectionIndex]?.buttonColor == "blue"}
        isWide
      />
    </ButtonContainer>
  );

  const ActiveSection = (
    <div>
      <div className={classes.marketing_benefits_icons}>
        {sections[activeSectionIndex].icons.map((icon, idx) => (
          <div key={`icon_${idx}`} className={classes.marketing_benefits_icon}>
            <div
              className={classes.marketing_benefits_icon_img}
              style={{ backgroundImage: `url(${icon.iconUrl})` }}
            ></div>
            <div dangerouslySetInnerHTML={{ __html: icon.description }}></div>
          </div>
        ))}
      </div>
      {MaybeShowCta}
    </div>
  );

  const containerClasses = [classes.marketing__benefits];
  if (sections[activeSectionIndex]?.className) {
    containerClasses.push(sections[activeSectionIndex].className);
  }

  return (
    <>
      <section className={`${classes.marketing__video}`}>
        <Container>
          <video
            controls
            muted={false}
            style={{ maxHeight: "65vh", maxWidth: "calc(100vw - 3rem)" }}
          >
            <source src="/explainer-video.mp4" />
          </video>
        </Container>
      </section>
      <div className={containerClasses.join(" ")}>
        <Container>
          <h2>High-Level Service Benefits</h2>

          <div className={classes.marketing__benefits_tabs}>{Tabs}</div>

          {ActiveSection}
        </Container>

        <IconLogoMarkLight className={classes.marketing__benefits_tailicon} />
      </div>
    </>
  );
};

export { Benefits };
